import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import heroImg from '../../images/posts/009/anchors-for-headings-in-mdx.svg';
import H2Example from '../../components/PostExtras/H2Example/h2-example';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeroPost gradientFrom="#C2E9FB" gradientTo="#A1C4FD" mdxType="HeroPost">
  <img src={heroImg} alt="Anchor and waves" height="246" />
    </HeroPost>
    <h1>{`Anchors for headings in MDX`}</h1>
    <Date date="February 07, 2021" dateMachine="2021-02-07" mdxType="Date" />
    <p>{`How to add anchors to headings in MDX? It's surprisingly easy to do. I assume you are already familiar with MDX
so somewhere in your code you should have a blog page layout component that uses `}<inlineCode parentName="p">{`<MDXProvider>`}</inlineCode>{`, like that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MDXProvider>{children}</MDXProvider>
`}</code></pre>
    <p>{`MDX consists of components itself. So if there is a code like `}<inlineCode parentName="p">{`this`}</inlineCode>{` then the interpreter
changes the inner value (children) into the same content but wrapped with `}<inlineCode parentName="p">{`<code></code>`}</inlineCode>{`.`}</p>
    <p>{`The same applies to all the headings, lists, paragraphs, etc. Our job is to override the headings. We can do it by passing
`}<inlineCode parentName="p">{`components`}</inlineCode>{` hash and specifying a replacement.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import H2 from './MyCustomMDX/H2';
import H3 from './MyCustomMDX/H3';
import H4 from './MyCustomMDX/H4';

// ...

<MDXProvider
  components={{
    h2: H2,
    h3: H3,
    h4: H4,
  }}
>
  {children}
</MDXProvider>;
`}</code></pre>
    <p>{`Please notice that we are not going to add an anchor to the `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` tag. It doesn't make sense in my opinion. `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` is like a summary
of the whole page. The URL that links to it is the direct link to the post. Anchors should be used to specific parts of a post
(to a section).`}</p>
    <h2>{`Override heading component`}</h2>
    <p>{`The override for `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{` that shows an anchor when the mouse is over the text could look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{showLines},{codesandboxUrl=https://codesandbox.io/s/gifted-sunset-2ncim?file=/src/H2.js}",
        "{showLines},{codesandboxUrl": "https://codesandbox.io/s/gifted-sunset-2ncim?file"
      }}>{`// ./MyCustomMDX/H2.js

function getAnchor(text) {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/[ ]/g, '-');
}

const H2 = ({ children }) => {
  const anchor = getAnchor(children);
  const link = \`#\${anchor}\`;
  return (
    <h2 id={anchor}>
      <a href={link} className="anchor-link">
        §
      </a>
      {children}
    </h2>
  );
};

export default H2;
`}</code></pre>
    <p>{`Below you'll see the demo. Please hover over the text. On the left you should see `}<inlineCode parentName="p">{`§`}</inlineCode>{` sign that is also a link, representing
our anchor:`}</p>
    <H2Example mdxType="H2Example">I'm h2 with an anchor</H2Example>
    <p>{`Let's explain a few bits. The way we use headings in Markdown is by using `}<inlineCode parentName="p">{`#`}</inlineCode>{` sign, for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`## I'm h2 with an anchor
`}</code></pre>
    <p>{`Everything that goes after `}<inlineCode parentName="p">{`## `}</inlineCode>{` `}{`is passed as a child to the `}<inlineCode parentName="p">{`H2`}</inlineCode>{` component.`}</p>
    <p>{`So the next interesting bit is done in the `}<inlineCode parentName="p">{`getAnchor`}</inlineCode>{` function. Take a look at lines `}<span className="line-number">{`3`}</span>{` to `}<span className="line-number">{`8`}</span>{`.
This is what happens:`}</p>
    <ul>
      <li parentName="ul">{`line `}<span className="line-number">{`5`}</span>{` - we convert the input to lower case `}{`→`}{` "i'm h2 with an anchor"`}</li>
      <li parentName="ul">{`line `}<span className="line-number">{`6`}</span>{` - we remove all non-alphanumeric characters `}{`→`}{` "im h2 with an anchor"`}</li>
      <li parentName="ul">{`line `}<span className="line-number">{`7`}</span>{` - we replace spaces with a hyphen `}{`→`}{` "im-h2-with-an-anchor"`}</li>
    </ul>
    <p>{`... and voilà. We have a URL-friendly anchor 🎉`}</p>
    <h2>{`The styling`}</h2>
    <p>{`Another important thing here is the CSS. We want to show the anchor only on hover and somewhere next to the heading itself:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h2 {
  position: relative;
}

.anchor-link {
  color: #666;
  opacity: 0;
  position: absolute;
  transform: translate(-1em, -2px);
  width: 1em;
}

h2:hover .anchor-link {
  opacity: 1;
}
`}</code></pre>
    <p>{`Of course, you can go crazy with your anchors ;) That one is very basic.`}</p>
    <h2>{`Recommendation`}</h2>
    <p>{`One thing that is easy to overlook here (in my example) is using a character like `}<inlineCode parentName="p">{`§`}</inlineCode>{` inside of `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{` tag.
In this approach, `}<strong parentName="p">{`the sign will become a part of the document outline`}</strong>{`. Which is not something we want.
It's better to use an icon in SVG format for example but I didn't want to complicate the example.`}</p>
    <p>{`If the simple sign is what you want then you should render `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` tag before or after the `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{`.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1358368420614578178" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Throttle vs Debounce on real examples" prevLink="/posts/throttle-vs-debounce-on-real-examples" nextTitle="Sorting colors in JavaScript" nextLink="/posts/sorting-colors-in-js" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      